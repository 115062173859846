import {Navbar} from "../components/Navbar";
import {Hero} from "../components/Hero";
import {Courses} from "../components/Courses";
import {About} from "../components/About";
import {Services} from "../components/Services";
import {Footer} from "../components/Footer";
import {ScrollToTop} from "../components/ScrollToTop";
import {useRef} from "react";
import {Faq} from "../components/Faq";
import {Certifications} from "../components/Certifications";

export function HomePage() {
    const coursesRef = useRef(null);
    const servicesRef = useRef(null);
    const faqRef = useRef(null);
    const certificationsRef = useRef(null);
    return <>
        <Navbar coursesRef={coursesRef} servicesRef={servicesRef} faqRef={faqRef} certificationsRef={certificationsRef}/>
        <Hero/>
        <Courses reference={coursesRef}/>
        <Certifications reference={certificationsRef}/>
        <About/>
        <Services reference={servicesRef}/>
        <Faq reference={faqRef}/>
        <Footer coursesRef={coursesRef} servicesRef={servicesRef}/>
        <ScrollToTop/>
    </>
}