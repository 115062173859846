import "./App.css";
import {HomePage} from "./pages/Home";
import {useLocation} from "react-router-dom";
import ReactGA from 'react-ga';
import {useEffect} from "react";


function usePageViews() {
      let location = useLocation();
      useEffect(() => {
            ReactGA.send(["pageview", location.pathname]);
      }, [location]);
}
function App() {
      const TRACKING_ID = "UA-306223465-1";
      ReactGA.initialize(TRACKING_ID);
      usePageViews();
      return <HomePage/>;
}

export default App;