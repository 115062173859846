export const Hero = () => {
    return (
        <section className="container grid lg:grid-cols-2 place-items-center py-20 md:py-32 gap-10">
            <div className="text-center lg:text-start space-y-6">
                <main className="text-5xl md:text-6xl font-bold">
                    <h1 className="inline">
            <span className="inline bg-gradient-to-r from-[#F596D3]  to-[#67c656] text-transparent bg-clip-text">
              Skill Leap
            </span>{" "}
                        is the landing place
                    </h1>{" "}
                    for{" "}
                    <h2 className="inline">
            <span
                className="inline bg-gradient-to-r from-[#67c656] via-[#67c670] to-[#03a3d7] text-transparent bg-clip-text">
              Leaping
            </span>{" "}
                        your career.
                    </h2>
                </main>

                <p className="text-xl text-muted-foreground md:w-10/12 mx-auto lg:mx-0">
                    Level up your skills with the extensive list of mentor ships and courses offered.
                </p>
            </div>

            {/*/!* Hero cards sections *!/*/}
            {/*<div className="z-10">*/}
            {/*    <HeroCards/>*/}
            {/*</div>*/}

            {/* Shadow effect */}
            <div className="shadow"></div>
        </section>
    );
};
