export const FrontEndCourseInfo = () => {
    return (
        <div className="font-mono leading-10 bg-scroll">
            <p className="text-3xl font-black text-orange-600">Master Frontend</p>
            <p className="text-xl font-bold text-green-800 my-2">Duration: 30 sessions (1session - 90 mins)</p>
            <p className="text-xl font-bold text-custom-blue my-2">Course curriculum</p>
            <ul className="list-disc">
                <li className="ml-5">
                    <span className="font-semibold">Internet - 1 session</span>
                    <ul className="list-disc ml-10">
                        <li>
                            How does internet works
                        </li>
                        <li>
                            What is http
                        </li>
                        <li>
                            What is domain name
                        </li>
                        <li>
                            What is hosting
                        </li>
                        <li>
                            DNS
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">HTML - 4 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Skeleton of website
                        </li>
                        <li>
                            Getting started with code
                        </li>
                        <li>
                            Setting up your system
                        </li>
                        <li>
                            HTML - Basics
                        </li>
                        <li>
                            HTML - Advanced
                        </li>
                        <li>
                            HTML - Accessibility
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">CSS - 4 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Basics
                        </li>
                        <li>
                            Making layouts with Grid and Flex
                        </li>
                        <li>
                            Responsive design
                        </li>
                        <li>
                            Using design systems - TailwindCSS
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Javascript - basics - 5 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Basics
                        </li>
                        <li>
                            DOM manipulation
                        </li>
                        <li>
                            Fetch API
                        </li>
                        <li>
                            Package managers (npm, yarn)
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Version control Systems - GIT - 1 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            What is git?
                        </li>
                        <li>
                            Learning enough git to be dangerous
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Checkpoint 1</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Recap of learnings
                        </li>
                        <li className="font-semibold text-cyan-500">
                            Building real world project using HTML,CSS & Plain JavaScript
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold">React - 15 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            What is React?
                        </li>
                        <li>
                            How react works? (More about virtual DOM)
                        </li>
                        <li>
                            CLI tools
                        </li>
                        <li>
                            Components
                        </li>
                        <li>
                            Rendering
                        </li>
                        <li>
                            Hooks
                        </li>
                        <li>
                            Routing with React Router
                        </li>
                        <li>
                            State management
                        </li>
                        <li>
                            Styling react application
                        </li>
                        <li>
                            Making API calls with Axios
                        </li>
                        <li>
                            Redux
                        </li>
                        <li>
                            Unit test writing with Jest
                        </li>
                        <li>
                            Handling Forms
                        </li>
                        <li>
                            React advanced concepts (Suspence, Context, etc)
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Checkpoint 2</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Recap of React
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl text-custom-green">Destination</p>
                    <ul className="list-disc ml-10">
                        <li className="font-bold text-custom-green">
                            Building a real world project using React
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export const BackEndCourseInfo = () => {
    return (
        <div className="font-mono leading-10 bg-scroll">
            <p className="text-3xl font-black text-orange-600">Master Backend</p>
            <p className="text-xl font-bold text-green-800 my-2">Duration: 30 sessions (1session - 90 mins)</p>
            <p className="text-xl font-bold text-custom-blue my-2">Course curriculum</p>
            <ul className="list-disc">
                <li className="ml-5">
                    <span className="font-semibold">Internet - 1 session</span>
                    <ul className="list-disc ml-10">
                        <li>
                            How does internet works
                        </li>
                        <li>
                            What is http
                        </li>
                        <li>
                            What is domain name
                        </li>
                        <li>
                            What is hosting
                        </li>
                        <li>
                            DNS
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Basics of programming with Javascript - 7 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Getting started with code
                        </li>
                        <li>
                            Data types, Type casting, Data Structures
                        </li>
                        <li>
                            Control flow
                        </li>
                        <li>
                            Loops and iterations
                        </li>
                        <li>
                            Functions
                        </li>
                        <li>
                            Modules
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Version control Systems - GIT - 1 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            What is git?
                        </li>
                        <li>
                            Learning enough git to be dangerous
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">NodeJS - 5 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            NodeJS - Basics
                        </li>
                        <li>
                            HTTP and Anatomy of request
                        </li>
                        <li>
                            Creating a HTTP server with NodeJS
                        </li>
                        <li>
                            Express
                        </li>
                        <li>
                            Middlewares
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">SQL - 5 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            What is database and SQL?
                        </li>
                        <li>
                            Getting started with database
                        </li>
                        <li>
                            Queries
                        </li>
                        <li>
                            Data manipulation in SQL
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Building API's - 5 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            CRUD
                        </li>
                        <li>
                            REST
                        </li>
                        <li>
                            GraphQL
                        </li>
                        <li>
                            User authentication and authorisation
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Checkpoint 1</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Recap of learnings
                        </li>
                        <li className="font-semibold text-cyan-500">
                            Building real world API with express,SQL,REST
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Optimising BE applications - 3 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Caching
                        </li>
                        <li>
                            Sharding
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Unit testing - 1 session</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Unit testing Mocha
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Checkpoint 2</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Recap of Learnings
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Devops 101 - 3 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            CI / CD
                        </li>
                        <li>
                            Deploying API's
                        </li>
                        <li>
                            Cloud with AWS
                        </li>
                        <li>
                            Deploying applications to Internet
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl text-custom-green">Destination</p>
                    <ul className="list-disc ml-10">
                        <li className="font-bold text-custom-green">
                            Building a real world project using React
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export const FullStackCourseInfo = () => {
    return (
        <div className="font-mono leading-10 bg-scroll">
            <p className="text-3xl font-black text-orange-600">Master FullStack</p>
            <p className="text-xl font-bold text-green-800 my-2">Duration: 50 sessions (1session - 90 mins)</p>
            <p className="text-xl font-bold text-custom-blue my-2">Course curriculum</p>
            <ul className="list-disc">
                <li className="ml-5">
                    <span className="font-semibold">Internet - 1 session</span>
                    <ul className="list-disc ml-10">
                        <li>
                            How does internet works
                        </li>
                        <li>
                            What is http
                        </li>
                        <li>
                            What is domain name
                        </li>
                        <li>
                            What is hosting
                        </li>
                        <li>
                            DNS
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">HTML - 2 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Skeleton of website
                        </li>
                        <li>
                            Getting started with code
                        </li>
                        <li>
                            Setting up your system
                        </li>
                        <li>
                            HTML - Basics
                        </li>
                        <li>
                            HTML - Advanced
                        </li>
                        <li>
                            HTML - Accessibility
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">CSS - 2 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Basics
                        </li>
                        <li>
                            Making layouts with Grid and Flex
                        </li>
                        <li>
                            Responsive design
                        </li>
                        <li>
                            Using design systems - TailwindCSS
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Javascript - basics - 7 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Basics
                        </li>
                        <li>
                            DOM manipulation
                        </li>
                        <li>
                            Fetch API
                        </li>
                        <li>
                            Package managers (npm, yarn)
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Version control Systems - GIT - 1 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            What is git?
                        </li>
                        <li>
                            Learning enough git to be dangerous
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Checkpoint 1</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Recap of learnings
                        </li>
                        <li className="font-semibold text-cyan-500">
                            Building real world project using HTML,CSS & Plain JavaScript
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">NodeJS - 5 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            NodeJS - Basics
                        </li>
                        <li>
                            HTTP and Anatomy of request
                        </li>
                        <li>
                            Creating a HTTP server with NodeJS
                        </li>
                        <li>
                            Express
                        </li>
                        <li>
                            Middlewares
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">SQL - 5 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            What is database and SQL?
                        </li>
                        <li>
                            Getting started with database
                        </li>
                        <li>
                            Queries
                        </li>
                        <li>
                            Data manipulation in SQL
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-semibold">Building API's - 5 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            CRUD
                        </li>
                        <li>
                            REST
                        </li>
                        <li>
                            GraphQL
                        </li>
                        <li>
                            User authentication and authorisation
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Checkpoint 1</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Recap of learnings
                        </li>
                        <li className="font-semibold text-cyan-500">
                            Building real world end to end web application with HTML,CSS,JS,NodeJS,Express,SQL
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold">React - 10 session/s</p>
                    <ul className="list-disc ml-10">
                        <li>
                            What is React?
                        </li>
                        <li>
                            How react works? (More about virtual DOM)
                        </li>
                        <li>
                            CLI tools
                        </li>
                        <li>
                            Components
                        </li>
                        <li>
                            Rendering
                        </li>
                        <li>
                            Hooks
                        </li>
                        <li>
                            Routing with React Router
                        </li>
                        <li>
                            State management
                        </li>
                        <li>
                            Styling react application
                        </li>
                        <li>
                            Making API calls with Axios
                        </li>
                        <li>
                            Redux
                        </li>
                        <li>
                            Unit test writing with Jest
                        </li>
                        <li>
                            Handling Forms
                        </li>
                        <li>
                            React advanced concepts (Suspence, Context, etc)
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Checkpoint 2</p>
                    <ul className="list-disc ml-10">
                        <li>
                            Recap of Learnings
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl">Devops 101 - 3 sessions</p>
                    <ul className="list-disc ml-10">
                        <li>
                            CI / CD
                        </li>
                        <li>
                            Deploying API's
                        </li>
                        <li>
                            Cloud with AWS
                        </li>
                        <li>
                            Deploying applications to Internet
                        </li>
                    </ul>
                </li>
                <li className="ml-5">
                    <p className="font-bold text-xl text-custom-green">Destination</p>
                    <ul className="list-disc ml-10">
                        <li className="font-bold text-custom-green">
                            Building a real world project from scratch
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}