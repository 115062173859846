import {Component, useState} from "react";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "./ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";

import { Button, buttonVariants } from "./ui/button";
import { MailIcon, Menu, PhoneIcon } from "lucide-react";
import { WhatsappIcon } from "./Icons";
import LogoImage from "../assets/logo.png";
import { Flex, Dialog } from "@radix-ui/themes";
import ReactGA from "react-ga";

type NavbarProps = {
  coursesRef: any,
  servicesRef: any,
  faqRef: any,
  certificationsRef: any
}

interface RouteProps {
  reference: any,
  label: string
}


class ContactUs extends Component {
  render() {
    return <div className="md:flex gap-2">
      <Dialog.Root>
        <Dialog.Trigger>
          <Button onClick={() => {
            ReactGA.event({category: "User interaction", action: 'Clicked button', label: 'Talk to us'})
          }}>
            <PhoneIcon className="mr-2 w-5 h-5"/>
            Talk to us
          </Button>
        </Dialog.Trigger>

        <Dialog.Content>
          <Dialog.Title>Contact us</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            You can reach us on
          </Dialog.Description>

          <Flex direction="column" gap="3">
            <a href="tel:+919912554877">
              <Button
                  variant="link"
                  className="bg-cyan-300 text-white rounded-xl"
              >
                <Flex direction="row" gap="3">
                  <PhoneIcon/> +91 9912554877
                </Flex>
              </Button>
            </a>
            <a href="mailto:skillleap.in@gmail.com">
              <Button
                  variant="link"
                  className="bg-yellow-700 text-white rounded-xl"
              >
                <Flex direction="row" gap="3">
                  <MailIcon/> skillleap.in@gmail.com
                </Flex>
              </Button>
            </a>
            <a href="https://wa.link/lsrcir">
              <Button
                  variant="link"
                  className="bg-lime-600 text-white rounded-xl"
              >
                <Flex direction="row" gap="3">
                  <WhatsappIcon/> +91 9912554877
                </Flex>
              </Button>
            </a>
            <a href="https://wa.link/1wtt4y">
              <Button
                  variant="link"
                  className="bg-lime-600 text-white rounded-xl"
              >
                <Flex direction="row" gap="3">
                  <WhatsappIcon/> +91 8466879073
                </Flex>
              </Button>
            </a>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </div>;
  }
}

export const Navbar = ({coursesRef, servicesRef, faqRef, certificationsRef}: NavbarProps) => {
  const routeList: RouteProps[] = [
    {
      reference: coursesRef,
      label: "Courses",
    },
    {
      reference: certificationsRef,
      label: "Certifications",
    },
    {
      reference: servicesRef,
      label: "Services",
    },
    {
      reference: faqRef,
      label: "FAQ",
    },
  ];
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <header className="sticky border-b-[1px] top-0 z-40 w-full bg-white dark:border-b-slate-700 dark:bg-background">
      <NavigationMenu className="mx-auto">
        <NavigationMenuList className="container h-14 px-4 w-screen flex justify-between ">
          {/* mobile */}
          <span className="flex md:hidden">
            <Sheet open={isOpen} onOpenChange={setIsOpen}>
              <SheetTrigger className="px-2">
                <Menu
                    className="flex md:hidden h-5 w-5"
                    onClick={() => setIsOpen(true)}
                >
                  <span className="sr-only">Menu Icon</span>
                </Menu>
              </SheetTrigger>

              <SheetContent side={"left"} className="bg-white">
                <SheetHeader>
                  <SheetTitle className="font-bold text-xl">
                    Skill Leap
                  </SheetTitle>
                </SheetHeader>
                <nav className="flex flex-col justify-center items-center gap-2 mt-4">
                  {routeList.map(({reference, label}: RouteProps) => (
                      <button
                          key={label}
                          onClick={() => {
                            window.scrollTo(
                                {
                                  top: reference.current.offsetTop,
                                  behavior: "smooth"
                                }
                            )
                            setIsOpen(false)
                          }}
                          className={buttonVariants({variant: "ghost"})}
                      >
                        {label}
                      </button>
                  ))}
                </nav>
              </SheetContent>
            </Sheet>
          </span>

          <NavigationMenuItem className="font-bold flex">
            <a href="/" className="ml-2 font-bold text-xl flex">
              <img src={LogoImage} width="200" height="100"/>
            </a>
          </NavigationMenuItem>

          {/* desktop */}
          <nav className="hidden md:flex gap-2">
            {routeList.map((route: RouteProps, i) => (
                <button
                    key={i}
                    onClick={() => {
                      window.scrollTo({
                        top: route.reference.current.offsetTop,
                        behavior: "smooth"
                      })
                    }}
                    className={`text-[17px] ${buttonVariants({
                      variant: "ghost",
                    })}`}
                >
                  {route.label}
                </button>
            ))}
          </nav>

          <ContactUs/>
        </NavigationMenuList>
      </NavigationMenu>
    </header>
  );
};
