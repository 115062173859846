import React from "react";
import LogoImage from "../assets/logo.png";

export const Footer = ({coursesRef, servicesRef}: any) => {
    return (
        <footer id="footer">
            <hr className="w-11/12 mx-auto"/>

            <section className="container py-20 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-x-12 gap-y-8">
                <div className="col-span-full xl:col-span-2">
                    <a href="/" className="font-bold text-xl flex">
                        <img src={LogoImage} width="200" height="100" alt="SkillLeap logo"/>
                    </a>
                </div>

                <div className="flex flex-col gap-2">
                    <h3 className="font-bold text-lg">About</h3>
                    <div>
                        <button className="opacity-60 hover:opacity-100" onClick={() => {
                            window.scrollTo({
                                top: coursesRef.current.offsetTop,
                                behavior: "smooth"
                            })
                        }}>
                            Courses
                        </button>
                    </div>

                    <div>
                        <button className="opacity-60 hover:opacity-100" onClick={() => {
                            window.scrollTo({
                                top: servicesRef.current.offsetTop,
                                behavior: "smooth"
                            })
                        }}>
                            Services
                        </button>
                    </div>

                    <div>
                        <button className="opacity-60 hover:opacity-100">
                            FAQ
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <h3 className="font-bold text-lg">Community</h3>
                    <div>
                        <a href="#" className="opacity-60 hover:opacity-100">
                            Discord
                        </a>
                    </div>
                    <div>
                        <a href="#" className="opacity-60 hover:opacity-100">
                            Telegram
                        </a>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <h3 className="font-bold text-lg">Locations</h3>
                    <div>
                        <p className="opacity-60 hover:opacity-100">
                            Visakhapatnam
                        </p>
                    </div>
                    <div>
                        <p className="opacity-60 hover:opacity-100">
                            Hyderabad
                        </p>
                    </div>
                </div>
            </section>

            <section className="container pb-14 text-center">
                <h3>
                    &copy;
                    SkillLeap.in
                </h3>
            </section>
        </footer>
    );
};
