import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import image from "../assets/growth.png";
import image3 from "../assets/reflecting.png";
import image4 from "../assets/looking-ahead.png";
import { Button } from "./ui/button";
import {Dialog, ScrollArea} from "@radix-ui/themes";
import {BackEndCourseInfo, FrontEndCourseInfo, FullStackCourseInfo} from "./CourseInfo";
import React, {ReactElement} from "react";
import ReactGA from "react-ga";

interface CourseInfoProps {
  name?: string;
  description?: string;
  imageUrl?: string;
  price?: number;
  offerPrice?: number;
  CourseDetailsInfoContent?: React.JSX.Element
}

const courses: CourseInfoProps[] = [
  {
    name: "Frontend",
    description:
      "Master designing rich UI applications with HTML/CSS/JS and industry standard framework React",
    imageUrl: image,
    price: 8000,
    offerPrice: 5500,
    CourseDetailsInfoContent: <FrontEndCourseInfo/>
  },
  {
    name: "Backend",
    description: "Master Backend with Node JS and SQL and NoSQL",
    imageUrl: image3,
    price: 8000,
    offerPrice: 5500,
    CourseDetailsInfoContent: <BackEndCourseInfo/>
  },
  {
    name: "FullStack",
    description: "Full Stack Development with React and Node JS - LIVE",
    imageUrl: image4,
    price: 11000,
    offerPrice: 8000,
    CourseDetailsInfoContent: <FullStackCourseInfo/>
  },
];

export const Courses = ({reference}: any) => {
  return (
      <section id="sponsors" className="container pt-24 sm:py-32" ref={reference}>
      <div className="bg-muted/50 border rounded-lg py-12">
        <h2 className="text-center text-3xl md:text-4xl font-bold">
          Courses Offered
        </h2>

        <div className="flex flex-wrap justify-center items-center gap-4 md:gap-8">
          {courses.map(({name, description, imageUrl, CourseDetailsInfoContent}: CourseInfoProps) => (
            <CourseInfo
              key={name}
              name={name}
              description={description}
              imageUrl={imageUrl}
              CourseDetailsInfoContent={CourseDetailsInfoContent}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const CourseInfo = ({name, description, imageUrl, CourseDetailsInfoContent}: CourseInfoProps) => {
  return (
    <Card key={name} style={{ maxWidth: 320 }} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 my-8 border-green-200 shadow-2xl">
      <CardHeader>
        <CardTitle>{name}</CardTitle>
      </CardHeader>

      <CardContent>
        <img
          src={imageUrl}
          alt="About feature"
          className="w-[200px] lg:w-[300px] mx-auto"
        />
        {description}
      </CardContent>
      <CardFooter>
        <div className="md:flex gap-2">
          <Dialog.Root>
            <Dialog.Trigger>
              <Button variant="link" className="text-custom-blue">
                More info
              </Button>
            </Dialog.Trigger>

            <Dialog.Content>
              <ScrollArea type="always" scrollbars="vertical" style={{height: "500px"}}>
                {CourseDetailsInfoContent}
              </ScrollArea>
            </Dialog.Content>
          </Dialog.Root>
        </div>
        <a href="https://forms.gle/4wrDZZ9YWTCYyKr78" target="_blank">
          <Button
            variant="outline"
            className="w-full md:w-auto"
            onClick={() => {
              ReactGA.event({category: "User interaction", action: 'Clicked button', label: 'Request demo'})
            }}
          >
            Request Demo
          </Button>
        </a>
      </CardFooter>
    </Card>
  );
};
