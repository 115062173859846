import { Card, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { GoDatabase } from "react-icons/go";
import { CiCloudDrizzle } from "react-icons/ci";
import { FaReact } from "react-icons/fa";
import { FaLinode } from "react-icons/fa";



import {MagnifierIcon, WalletIcon, ChartIcon, LightBulbIcon, GiftIcon} from "./Icons";
import cubeLeg from "../assets/cube-leg.png";

interface ServiceProps {
    title: string;
    description: string;
    icon: JSX.Element;
}

interface ProjectProps {
    title: string;
    description: Array<string>;
    icon: JSX.Element;
}


const serviceList: ServiceProps[] = [
    {
        title: "Internship",
        description:
            "Get real world experience by building live applications",
        icon: <LightBulbIcon/>,
    },
    {
        title: "Mentoring",
        description:
            "Live group mentoring  with frequent doubt clearing sessions",
        icon: <WalletIcon />,
    },
    {
        title: "Expert career guidance",
        description:
            "Expert career guidance to help you make the right decisions",
        icon: <GiftIcon/>,
    },
    {
        title: "Mock Interviews",
        description:
            "Mock interviews from industry experts",
        icon: <MagnifierIcon />,
    },
];
const projectList: ProjectProps[] = [
    {
        title: "FrontEnd",
        description:["React Js", "Next JS","Angular Js"],
        icon: <FaReact size={40} />,
    },
    {
        title: "BackEnd",
        description:["NodeJs", "Java","Golang","ExpressJs"],
        icon: <FaLinode size={40} />,
    },
    {
        title: "Database",
        description:["MySQL","NoSQL"],
        icon: < GoDatabase size={40}/>,
    },
    {
        title: "Cloud & Devops",
        description:["AWS","Azure","Gcp","Kubernetes"],
        icon: <CiCloudDrizzle size={40}/>,
    },
];

export const Services = ({reference}: any) => {
    return (
        <section className="container py-24 sm:py-32" ref={reference}>
            <div className="grid lg:grid-cols-[1fr,1fr] gap-8 place-items-center">
                <div>
                    <h2 className="text-3xl md:text-4xl font-bold text-center">
                        Services
                    </h2>

                    <p className="text-muted-foreground text-xl mt-4 mb-8 text-center">
                        We offer different types of mentoring
                    </p>

                    <div className="flex flex-col gap-8">
                        {serviceList.map(({ icon, title, description }: ServiceProps) => (
                            <Card key={title}>
                                <CardHeader className="space-y-1 flex md:flex-row justify-start items-start gap-4">
                                    <div className="mt-1 bg-primary/20 p-1 rounded-2xl">
                                        {icon}
                                    </div>
                                    <div>
                                        <CardTitle>{title}</CardTitle>
                                        <CardDescription className="text-md mt-2">
                                            {description}
                                        </CardDescription>
                                    </div>
                                </CardHeader>
                            </Card>
                        ))}
                    </div>
                </div>
                <div className=" ">
                    <h2 className="text-3xl md:text-4xl font-bold text-center">
                        Projects Handling
                    </h2>

                    <p className="text-muted-foreground text-xl mt-4 mb-8 text-center">
                        We are expertise in Web, Android, IOS Developement.
                    </p>

                    <div className="flex flex-col gap-8">
                        {projectList.map(({ icon, title, description }: ProjectProps) => (
                            <Card key={title}>
                                <CardHeader className="space-y-1 flex md:flex-row justify-start items-start gap-4">
                                    <div className="mt-1 bg-primary/20 p-1 rounded-2xl ">
                                        {icon}
                                    </div>
                                    <div>
                                        <CardTitle>{title}</CardTitle>
                                        <CardDescription className="text-md mt-2">
                                             {description.join(",")}
                                        </CardDescription>
                                    </div>
                                </CardHeader>
                            </Card>
                        ))}
                    </div>
                </div>

              
            </div>
        </section>
    );
};



