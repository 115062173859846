import React from "react";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "./ui/accordian";

interface FAQProps {
    question: string;
    answer: string;
    value: string;
}

const FAQList: FAQProps[] = [
    {
        question: "What is SkillLeap?",
        answer: "SkillLeap is an online platform offering a range of courses in web development, including frontend, backend, and full stack development, designed to help students gain valuable skills in the field of web development.",
        value: "item-1",
    },
    {
        question: "How is SkillLeap different from other platforms?",
        answer: "Unlike other platforms, at SkillLeap we only put 15 members in a batch. We also do 1-1 doubt clarifying session with each individual weekly once.",
        value: "item-2",
    },
    {
        question: "Who are the instructors?",
        answer:
            "Our courses are taught by industry professionals with extensive experience in web development. They bring real-world expertise and insights to the learning experience.",
        value: "item-3",
    },
    {
        question:
            "What will I learn in the frontend course?",
        answer:
            "The frontend course covers essential technologies and concepts such as HTML, CSS, JavaScript, responsive design, and frameworks like React. You'll learn to create dynamic and interactive user interfaces for websites.",
        value: "item-4",
    },
    {
        question: "What will I learn in the backend course?",
        answer: "In the backend course, you'll dive into server-side programming languages like Node.js or Python, along with databases such as Postgres or MySQL. You'll learn to build the logic and functionality that powers the behind-the-scenes of web applications.",
        value: "item-5",
    },
    {
        question:
            "What is included in the full stack course?",
        answer:
            "The full stack course combines both frontend and backend development, providing a comprehensive understanding of building complete web applications from start to finish. You'll learn to integrate frontend and backend technologies seamlessly.",
        value: "item-6",
    },
    {
        question:
            "Are there any prerequisites for enrolling in the courses?",
        answer:
            "No, we teach our courses such a way that person with no programming background can be up and running no time.",
        value: "item-7",
    },
    {
        question:
            "How long does each course take to complete?",
        answer:
            "Our course duration varies from course to course. Both Frontend and Backend course can be completed in 30 days and extra 15 days for building project. Full stack course takes about 45 days and 15 days extra for project.",
        value: "item-8",
    },
    {
        question:
            "Do I receive a certificate upon completion of a course?",
        answer:
            "Yes, upon successful completion of a course, you'll receive a certificate of achievement, which you can showcase on your resume or LinkedIn profile to demonstrate your skills and expertise.",
        value: "item-9",
    },
];

export const Faq = ({reference}:any) => {
    return (
        <section
            id="faq"
            className="container py-24 sm:py-32"
            ref={reference}
        >
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
                Frequently Asked Questions
            </h2>

            <Accordion
                type="single"
                collapsible
                className="w-full AccordionRoot"
            >
                {FAQList.map(({question, answer, value}: FAQProps) => (
                    <AccordionItem
                        key={value}
                        value={value}
                    >
                        <AccordionTrigger className="text-left">
                            {question}
                        </AccordionTrigger>

                        <AccordionContent>{answer}</AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>

            <h3 className="font-medium mt-4">
                Still have questions? Contact us
            </h3>
        </section>
    );
};
